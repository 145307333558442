import { useEffect, useState } from 'react';

import type { TickerItemType } from '@/layout/containers/default-container/ticker/ticker';
import fetchTickerInfo from '@/utils/functions/api/fetch-ticker-info';

const usePriceTicker = (): TickerItemType[] => {
    const [tickerItems, setTickerItems] = useState<TickerItemType[]>([]);

    useEffect(() => {
        //     '^GSPC': 'S&P 500',
        //     'GC=F': 'GOLD',
        //     'CL=F': 'OIL',
        //     'BTC-USD': 'BTC',
        //     'ETH-USD': 'ETH',
        //     'BNB-USD': 'BNB',
        //     'ADA-USD': 'ADA',
        //     'SOL-USD': 'SOL',
        //     'AVAX-USD': 'AVAX',
        //     'UNI1-USD': 'UNI',
        //     'AAVE-USD': 'AAVE',

        let interval: NodeJS.Timeout;

        const fetchPrices = () => {
            try {
                fetchTickerInfo().then(items => {
                    if (!items || items.length === 0 || !items.map) {
                        // error retrieving items
                        return;
                    }

                    setTickerItems(items);
                });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        };

        const stopTicker = () => clearInterval(interval);

        const startTicker = () => {
            fetchPrices();
            interval = setInterval(fetchPrices, 30000);
        };

        const onVisibilityChange = () => {
            // Always stop ticker on visibility change
            stopTicker();
            // Restart if visible
            if (document.visibilityState === 'visible') {
                startTicker();
            }
        };

        startTicker();
        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            stopTicker();
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, []);

    return tickerItems;
};

export default usePriceTicker;
