import * as React from 'react';

import { useTrack } from '@blockworks/platform/services/analytics';
import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

type MainMenuItemProps = {
    name: string;
    prefetch?: boolean;
    url: string;
    className?: string;
    linkClassName?: string;
    openInNewWindow?: boolean;
    onClick?: () => void;
};

const MainMenuItem = (props: MainMenuItemProps) => {
    const { name, url, className, linkClassName, prefetch, openInNewWindow, onClick } = props;
    const track = useTrack();

    return (
        <div className={cn('gap-2.5', className)}>
            <Link
                prefetch={!!prefetch}
                href={url}
                rel={openInNewWindow ? 'nofollow' : undefined}
                target={openInNewWindow ? '_blank' : ''}
                onClick={() => {
                    track('Menu Clicked', { url, name, openInNewWindow, type: 'Main Menu Item' });
                    onClick && onClick();
                }}
                className={linkClassName || 'block uppercase hover:text-light-gray'}
            >
                {name}
            </Link>
        </div>
    );
};

export default MainMenuItem;
