import * as React from 'react';
import Script from 'next/script';

import isStorageAvailable from '@/utils/functions/storage/is-storage-available';

const Scripts = () => {
    return (
        <>
            {isStorageAvailable('sessionStorage') && (
                <Script
                    src="https://www.lightboxcdn.com/vendor/ed0e899e-6406-4c1f-8314-7946ede4d143/lightbox_speed.js"
                    strategy="lazyOnload"
                />
            )}
        </>
    );
};

export default Scripts;
