import { ReactNode } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

const NoResultsFound = ({ title, description, icon }: { title: string; description?: string; icon?: ReactNode }) => {
    return (
        <div className="flex items-center justify-center w-full h-full">
            <div className="text-center mt-10 mb-10">
                <div> {icon ? icon : <ExclamationCircleIcon className="mx-auto h-12 w-12 text-gray-400" />} </div>
                <h2 className="mt-2 text-xl font-semibold text-gray-700">{title}</h2>
                {description && <p className="mt-2 text-gray-500">{description}</p>}
            </div>
        </div>
    );
};

export { NoResultsFound };
