import { PlatformConfig } from '@blockworks/platform';

const EditorialConfig = {
    ...PlatformConfig,
    siteUrl: process.env.SITE_URL!,
    site_name: 'Blockworks',
    title: 'Blockworks: News and insights about digital assets.',
    description:
        'Blockworks is a financial media brand that delivers breaking news and premium insights about digital assets to millions of investors. Our editorial content, newsletters, podcasts and events provide investors with the critical analysis and information they need to make smarter decisions about digital assets.',
    blockworksResearchUrl: 'https://app.blockworksresearch.com',
    wpApiUrl: process.env.WORDPRESS_API_URL || 'https://cms.blockworks.co/wp-json/',
};

export default EditorialConfig;
