import NextLink from 'next/link';

const Link = ({ href, prefetch, ...rest }: React.ComponentPropsWithRef<typeof NextLink>) => {
    if (typeof href === 'string' && !href.startsWith('/')) {
        return <a href={href} {...rest} />;
    }
    return <NextLink prefetch={prefetch} href={href} {...rest} />;
};

export { Link };
