import React from 'react';

type ILogoProps = {
    className?: string;
};

const LogoBlack = (props: ILogoProps) => {
    return (
        <svg
            width="204"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_2"
            viewBox="0 0 451.45 71.05"
            className={props.className}
        >
            <g id="Layer_1-2">
                <g>
                    <path d="M25.02,0c13.54,0,21.2,7.95,21.2,18.74,0,7.16-4.32,12.95-10.11,14.92,6.77,1.67,12.27,7.85,12.27,16.49,0,11.28-8.44,19.43-21.49,19.43H0V0H25.02Zm-2.06,28.75c6.18,0,9.91-3.43,9.91-8.73s-3.43-8.64-10.01-8.64H13.35V28.75h9.62Zm1.37,29.54c6.57,0,10.5-3.43,10.5-9.03s-3.53-9.32-10.3-9.32H13.35v18.35h10.99Z" />
                    <g>
                        <path d="M51.71,69.58V0h13.05V69.58h-13.05Z" />
                        <path d="M117.85,45.44c0,14.82-10.89,25.61-25.32,25.61s-25.32-10.79-25.32-25.61,10.89-25.61,25.32-25.61,25.32,10.7,25.32,25.61Zm-13.05,0c0-9.13-5.89-13.74-12.27-13.74s-12.27,4.61-12.27,13.74,5.89,13.74,12.27,13.74,12.27-4.61,12.27-13.74Z" />
                        <path d="M132.01,45.44c0,8.73,5.69,13.64,12.36,13.64s10.01-4.42,11.09-8.14l11.48,3.83c-2.16,8.05-9.71,16.29-22.57,16.29-14.23,0-25.41-10.79-25.41-25.61s10.99-25.61,25.02-25.61c13.15,0,20.61,8.14,22.67,16.29l-11.68,3.92c-1.18-4.02-4.22-8.14-10.7-8.14s-12.27,4.81-12.27,13.54Z" />
                        <path d="M196.19,41.61l19.72,27.97h-15.99l-12.76-18.45-5.4,5.69v12.76h-13.05V0h13.05V39.26l16.68-17.96h17.07l-19.33,20.31Z" />
                        <path d="M254.81,21.3l10.4,31.11,8.93-31.11h12.95l-15.11,48.28h-13.05l-11.28-32.97-11.09,32.97h-13.25l-15.41-48.28h13.74l8.93,31.01,10.5-31.01h13.74Z" />
                        <path d="M334.96,45.44c0,14.82-10.89,25.61-25.32,25.61s-25.32-10.79-25.32-25.61,10.89-25.61,25.32-25.61,25.32,10.7,25.32,25.61Zm-13.05,0c0-9.13-5.89-13.74-12.27-13.74s-12.27,4.61-12.27,13.74,5.89,13.74,12.27,13.74,12.27-4.61,12.27-13.74Z" />
                        <path d="M366.85,34.25c-1.47-.29-2.75-.39-3.92-.39-6.67,0-12.46,3.24-12.46,13.64v22.08h-13.05V21.3h12.66v7.16c2.94-6.38,9.62-7.56,13.74-7.56,1.08,0,2.06,.1,3.04,.2v13.15Z" />
                        <path d="M396.4,41.61l19.72,27.97h-15.99l-12.76-18.45-5.4,5.69v12.76h-13.05V0h13.05V39.26l16.68-17.96h17.07l-19.33,20.31Z" />
                        <path d="M423.77,53.68c.29,3.83,3.14,7.36,8.83,7.36,4.32,0,6.38-2.26,6.38-4.81,0-2.16-1.47-3.93-5.2-4.71l-6.38-1.47c-9.32-2.06-13.54-7.65-13.54-14.42,0-8.64,7.65-15.8,18.06-15.8,13.74,0,18.35,8.73,18.94,13.93l-10.89,2.45c-.39-2.85-2.45-6.48-7.95-6.48-3.43,0-6.18,2.06-6.18,4.81,0,2.35,1.77,3.83,4.42,4.32l6.87,1.47c9.52,1.96,14.33,7.75,14.33,14.82,0,7.85-6.08,15.9-18.74,15.9-14.52,0-19.53-9.42-20.12-14.92l11.19-2.45Z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default LogoBlack;
