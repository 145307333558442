// @ts-nocheck

import { useRef } from 'react';
import { mergeProps, useFocusRing, useSlider, useSliderThumb, VisuallyHidden } from 'react-aria';
import { useSliderState } from 'react-stately';

import { cn } from '@blockworks/ui/utils';

const parseTime = seconds => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    seconds = seconds - hours * 3600 - minutes * 60;
    return [hours, minutes, seconds];
};

const formatTime = (seconds, totalSeconds = seconds) => {
    const totalWithoutLeadingZeroes = totalSeconds.slice(totalSeconds.findIndex(x => x !== 0));
    return seconds
        .slice(seconds.length - totalWithoutLeadingZeroes.length)
        .map(x => x.toString().padStart(2, '0'))
        .join(':');
};

const Thumb = props => {
    const { state, trackRef, focusProps, isFocusVisible, index, className } = props;
    const inputRef = useRef(null);
    const { thumbProps, inputProps } = useSliderThumb({ index, trackRef, inputRef }, state);

    return (
        <div
            className={cn('absolute top-1 -translate-x-1/2', className)}
            style={{
                left: `${state.getThumbPercent(index) * 100}%`,
            }}
        >
            <div
                {...thumbProps}
                onMouseDown={(...args) => {
                    thumbProps.onMouseDown(...args);
                    props.onChangeStart?.();
                }}
                onPointerDown={(...args) => {
                    thumbProps.onPointerDown(...args);
                    props.onChangeStart?.();
                }}
                className={cn(
                    'h-4 rounded-full',
                    isFocusVisible || state.isThumbDragging(index) ? 'w-1.5 bg-slate-900' : 'w-1 bg-slate-700',
                )}
            >
                <VisuallyHidden>
                    <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
                </VisuallyHidden>
            </div>
        </div>
    );
};

export const Slider = props => {
    const trackRef = useRef(null);
    const state = useSliderState(props);
    const { groupProps, trackProps, labelProps, outputProps } = useSlider(props, state, trackRef);
    const { focusProps, isFocusVisible } = useFocusRing();

    const currentTime = parseTime(state.getThumbValue(0));
    const totalTime = parseTime(state.getThumbMaxValue(0));

    return (
        <div
            {...groupProps}
            className="absolute inset-x-0 bottom-full flex flex-auto touch-none items-center gap-4 md:relative"
        >
            {props.label && (
                <label className="sr-only" {...labelProps}>
                    {props.label}
                </label>
            )}
            <div
                {...trackProps}
                onMouseDown={(...args) => {
                    trackProps.onMouseDown(...args);
                    props.onChangeStart?.();
                }}
                onPointerDown={(...args) => {
                    trackProps.onPointerDown(...args);
                    props.onChangeStart?.();
                }}
                ref={trackRef}
                className="relative w-full bg-slate-100 md:rounded-full"
            >
                <div
                    className={cn(
                        'h-2 md:rounded-r-md md:rounded-l-xl',
                        isFocusVisible || state.isThumbDragging(0) ? 'bg-slate-900' : 'bg-slate-700',
                    )}
                    style={{
                        width:
                            state.getThumbValue(0) === 0
                                ? 0
                                : `calc(${state.getThumbPercent(0) * 100}% - ${
                                      isFocusVisible || state.isThumbDragging(0) ? '0.3125rem' : '0.25rem'
                                  })`,
                    }}
                />
                <Thumb
                    index={0}
                    state={state}
                    trackRef={trackRef}
                    onChangeStart={props.onChangeStart}
                    focusProps={focusProps}
                    isFocusVisible={isFocusVisible}
                />
            </div>
            <div className="hidden items-center gap-2 md:flex">
                <output
                    {...outputProps}
                    aria-live="off"
                    className={cn(
                        'hidden rounded-md px-1 py-0.5 font-mono text-sm leading-6 md:block',
                        state.getThumbMaxValue(0) === 0 && 'opacity-0',
                        isFocusVisible || state.isThumbDragging(0) ? 'bg-slate-100 text-slate-900' : 'text-slate-500',
                    )}
                >
                    {formatTime(currentTime, totalTime)}
                </output>
                <span className="text-sm leading-6 text-slate-300" aria-hidden="true">
                    /
                </span>
                <span
                    className={cn(
                        'hidden rounded-md px-1 py-0.5 font-mono text-sm leading-6 text-slate-500 md:block',
                        state.getThumbMaxValue(0) === 0 && 'opacity-0',
                    )}
                >
                    {formatTime(totalTime)}
                </span>
            </div>
        </div>
    );
};
