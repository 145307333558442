import * as React from 'react';
import { useEffect } from 'react';
import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';
import mixpanelPlugin from '@analytics/mixpanel';
import { originalSourcePlugin } from '@analytics/original-source-plugin';
import { Analytics as NextAnalytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { useRouter } from 'next/router';

import { AnalyticsProvider, useAnalytics } from '@blockworks/platform/services/analytics';
import { ChartbeatPlugin } from '@blockworks/platform/services/analytics/plugins/chartbeat';

import EditorialConfig from '@/editorial-config';
import { IMetaProps } from '@/layout/head/default-meta';
import onPageView from '@/utils/functions/analytics/on-page-view';

type AnalyticsProps = React.PropsWithChildren<{
    meta: IMetaProps;
}>;

const registerPlugins = (includeClientPlugins: boolean) => {
    const plugins = [originalSourcePlugin()];

    if (process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID) {
        plugins.push(
            googleAnalytics({
                measurementIds: [process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID],
            }),
        );
    }

    if (process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID) {
        plugins.push(
            googleTagManager({
                containerId: process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID,
            }),
        );
    }
    if (process.env.CHARTBEAT_UID) {
        plugins.push(ChartbeatPlugin({ uid: process.env.CHARTBEAT_UID, domain: 'blockworks.co' }));
    }

    // Add frontend plugins only during frontend rendering
    if (includeClientPlugins) {
        if (process.env.MIXPANEL_API_KEY) {
            plugins.push(
                mixpanelPlugin({
                    pageEvent: 'Page View',
                    token: process.env.MIXPANEL_API_KEY,
                    customScriptSrc: `${EditorialConfig.SITE_URL}/mp/lib.min.js`,
                    options: {
                        api_host: `${EditorialConfig.SITE_URL}/mp`,
                        persistence: 'localStorage',
                        verbose: process.env.NODE_ENV === 'development',
                        debug: process.env.NODE_ENV === 'development',
                    },
                }),
            );
        }
    }
    return plugins;
};

const PageView = ({ meta }: AnalyticsProps) => {
    const analytics = useAnalytics();
    const router = useRouter();

    useEffect(() => {
        // Track page view
        onPageView(router.asPath, analytics, meta);
    }, [router.asPath, analytics, meta]);

    return null;
};

const Analytics = ({ children, meta }: AnalyticsProps) => {
    return (
        <AnalyticsProvider app="blockworks.co" version="1.0.0" registerPlugins={registerPlugins}>
            {children}
            <NextAnalytics />
            <SpeedInsights />
            <PageView meta={meta} />
        </AnalyticsProvider>
    );
};

export default Analytics;
