import * as React from 'react';

import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

export type FooterMenuItem = {
    name: string;
    url: string;
};

type FooterTopSectionProps = {
    className?: string;
    menuItemsCol1: FooterMenuItem[];
    menuItemsCol2: FooterMenuItem[];
};

const FooterMenu = (props: FooterTopSectionProps) => {
    const { className, menuItemsCol1, menuItemsCol2 } = props;

    return (
        <div
            className={cn(
                'grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-0 text-white uppercase text-xxs whitespace-nowrap p-8 md:text-center',
                className,
            )}
        >
            <div>
                <h5 className="font-bold mb-3 md:hidden">Sections</h5>
                <div className="grid grid-cols-1 md:grid-cols-7 md:mb-6 lg:mb-0 gap-2">
                    {menuItemsCol1.map(link => (
                        <div className="grid hover:text-purple-200 w-20" key={link.url}>
                            <Link prefetch={false} href={link.url}>
                                {link.name}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <h5 className="font-bold mb-3 md:hidden">Company</h5>
                <div className="grid grid-cols-1 md:grid-cols-6 gap-2">
                    {menuItemsCol2.map(link => (
                        <div className="grid hover:text-purple-200 w-20" key={link.url}>
                            <Link prefetch={false} href={link.url}>
                                {link.name}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FooterMenu;
