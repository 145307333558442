export enum NewsletterSubscription {
    Daily,
    Weekly,
    ResearchDaily,
    Events,
    Webinars,
    Empire,
    Lightspeed,
    OnTheMargin,
}

export const publicationIds = {
    [NewsletterSubscription.Daily]: 'pub_2d09a047-9004-4638-9d8f-96778cc945a8',
    [NewsletterSubscription.Weekly]: 'pub_3e2b572f-5604-4251-af96-228698616d1f',
    [NewsletterSubscription.ResearchDaily]: 'pub_0d66d0da-6de0-4f51-b043-4e1ec87d970e',
    [NewsletterSubscription.Events]: 'pub_794f0ed8-b4bf-48ee-828e-7586a5e9575d',
    [NewsletterSubscription.Webinars]: 'pub_461d2572-cd56-493c-8dc2-0580fda18730',
    [NewsletterSubscription.Empire]: 'pub_252bfc8d-0faa-4c84-9c29-22f27253e365',
    [NewsletterSubscription.Lightspeed]: 'pub_8ea337de-9a78-4628-afe5-cca9099a8dea',
    [NewsletterSubscription.OnTheMargin]: 'pub_4b1f3010-0e25-41c8-8bd3-fdc6db7ecfb9',
};
