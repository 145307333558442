import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

type FooterResearchTeaserProps = {
    className?: string;
};

const FooterResearchTeaser = (props: FooterResearchTeaserProps) => {
    const { className } = props;

    return (
        <div
            className={cn(
                'flex flex-col justify-center items-start self-stretch flex-grow gap-3 p-10 bg-[#110b29] border-t border-r-0 border-b-0 sm:border-l border-[#b4b4b4]/[0.35]',
                className,
            )}
        >
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2">
                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                        <p className="flex-grow-0 flex-shrink-0 text-[10px] text-left uppercase text-[#f7f7f7]">
                            blockworks research
                        </p>
                    </div>
                </div>
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                    <p className="flex-grow text-[22px] text-left text-white">
                        Unlock crypto&apos;s most powerful research platform.
                    </p>
                </div>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow text-sm text-left text-[#f7f7f7]">
                    Our research packs a punch and gives you actionable takeaways for each topic.
                </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-4">
                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-4">
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                            <a
                                href="https://blockworksresearch.com"
                                target="_blank"
                                className="flex-grow-0 flex-shrink-0 text-[10px] text-center uppercase text-[#a38bfc]"
                                rel="noreferrer"
                            >
                                subscribe
                            </a>
                        </div>
                        <svg
                            width="8"
                            height="9"
                            viewBox="0 0 8 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M4 0.837402L3.295 1.5424L6.085 4.3374H0V5.3374H6.085L3.295 8.1324L4 8.8374L8 4.8374L4 0.837402Z"
                                fill="#A38BFC"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-4">
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                            <a
                                href="https://app.blockworksresearch.com/support"
                                target="_blank"
                                className="flex-grow-0 flex-shrink-0 text-[10px] text-center uppercase text-[#a38bfc]"
                                rel="noreferrer"
                            >
                                get in touch
                            </a>
                        </div>
                        <svg
                            width="8"
                            height="9"
                            viewBox="0 0 8 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M4 0.837402L3.295 1.5424L6.085 4.3374H0V5.3374H6.085L3.295 8.1324L4 8.8374L8 4.8374L4 0.837402Z"
                                fill="#A38BFC"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterResearchTeaser;
