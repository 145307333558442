'use client';

import React, { useEffect, useState } from 'react';

import type { GlobalOptionProps } from '@/types/_app';
import FeatureFlags from '@/types/feature-flags';
import fetchWordpressOptions from '@/utils/functions/api/fetch-wordpress-options';
import replaceCmsUrl from '@/utils/functions/wordpress/replace-cms-url';

interface Options {
    readonly config: GlobalOptionProps;
    readonly loading: boolean;
    readonly featureFlags: FeatureFlags;
}

const initialOptions = {
    alertBarContent: '',
    tickerImageUrl: '',
    tickerUrl: '',
    keyMetricSponsor: '',
};
const OptionsContext = React.createContext<Options>({
    featureFlags: {},
    config: initialOptions,
    loading: false,
});

export const useOptions = () => {
    const result = React.useContext(OptionsContext);

    if (!result) {
        throw new Error('Context used outside of its Provider!');
    }

    return result;
};

const OptionsProvider = ({ featureFlags, children }: { featureFlags: FeatureFlags; children: any }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [config, setConfig] = useState<GlobalOptionProps>(initialOptions);

    useEffect(() => {
        // Prepare global app options
        fetchWordpressOptions().then(wpOptions => {
            setConfig({
                alertBarContent: replaceCmsUrl(wpOptions.alertBar?.markdownContent || ''),
                tickerImageUrl: wpOptions.tickerLogo || '',
                tickerUrl: wpOptions.tickerLink || '',
                keyMetricSponsor: wpOptions.keyMetricSponsor || '',
            });
            setLoading(false);
        });
    }, []);

    return <OptionsContext.Provider value={{ config, featureFlags, loading }}>{children}</OptionsContext.Provider>;
};

export default OptionsProvider;
