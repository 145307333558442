import { createContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import type { SelectInputOption } from '@/components/pages/news-archive/components/categories/select-input/select-input';

type TypeNewsArchiveCtx = {
    children?: any; // TODO: figure out the proper type here or rebuild the thing
};

const NewsArchiveCtx = createContext<any>({});

export const NewsArchiveProvider = ({ children }: TypeNewsArchiveCtx) => {
    // Navigation states
    const [query, setQuery] = useState<any>();
    // UI filter states
    const [showFilterUI, setShowFilterUI] = useState<any>(false);
    const [filterVals, setFilterVals] = useState<any>([]);
    // Separate select input state
    const [categories, setCategories] = useState<SelectInputOption[]>([]);
    const [author, setAuthor] = useState<SelectInputOption | null>(null);
    const [tags, setTags] = useState<SelectInputOption[]>([]);
    const [sort, setSort] = useState<any>();
    // Pagination
    const [page, setPage] = useState<any>(0);
    const [hasData, setHasData] = useState<any>(true);
    const [showBtn, setShowBtn] = useState<any>(false);
    const [showLoader, setShowLoader] = useState<any>(false);

    const router = useRouter();

    // Set the page query
    useEffect(() => {
        setQuery(router.query.category);
    }, [router.query]);

    // Reset page count on filter change
    useEffect(() => {
        setPage(0);
    }, [categories, tags, author, query]);

    // Get all selected categories slugs
    const categorySlugs = useMemo(() => categories.map((category: any) => category.slug), [categories]);

    // Get all selected tags slugs
    const tagsIDs = useMemo(() => tags.map((tag: any) => tag.slug), [tags]);

    let filtersArr: any = [];

    // Get all selected filters
    useEffect(() => {
        if (categories) {
            if (!filtersArr.includes(categories)) {
                filtersArr.push(categories);
            }
        }
        if (author) {
            if (!filtersArr.includes(author)) {
                filtersArr.push(author);
            }
        }
        if (tags) {
            if (!filtersArr.includes(tags)) {
                filtersArr.push(tags);
            }
        }
        if (sort) {
            if (!filtersArr.includes(sort)) {
                filtersArr.push(sort);
            }
        }

        if (filtersArr.length > 2 || filtersArr[0].length > 0 || filtersArr[1].length > 0) {
            setShowFilterUI(true);
            setFilterVals(filtersArr);
        } else {
            setShowFilterUI(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, author, tags, sort]);

    // Clear all filters
    const clearAllFilters = () => {
        if (router.asPath === `/news?category=${query}`) {
            router.push({ pathname: '/news' }, undefined, { shallow: true });
        }
        setPage(0);
        setShowFilterUI(false);
        filtersArr = [];
        setFilterVals([]);
        setCategories([]);
        setAuthor(null);
        setTags([]);
        setSort(null);
    };

    // Clear one filter at a time
    const clearFilter = (filter: any) => {
        const result = filterVals.filter((item: any) => item.id !== filter.id);

        filtersArr = result;

        if (result.length > 0) {
            setFilterVals(result);

            if (categories.length > 0) {
                const res = categories.filter((item: any) => filter.id !== item.id);

                if (!res.includes(query)) {
                    router.push({ pathname: '/news' }, undefined, {
                        shallow: true,
                    });
                }

                setCategories(res);
            }

            if (author) {
                if (filter.id === author.id) {
                    setAuthor(null);
                }
            }

            if (tags.length > 0) {
                const res = tags.filter((item: any) => filter.id !== item.id);

                setTags(res);
            }

            if (sort) {
                if (filter.id === sort.id) {
                    setSort(null);
                }
            }
        } else {
            clearAllFilters();
        }
    };

    return (
        <NewsArchiveCtx.Provider
            value={{
                showFilterUI,
                setShowFilterUI,
                filterVals,
                setFilterVals,
                categories,
                setCategories,
                author,
                setAuthor,
                tags,
                setTags,
                sort,
                setSort,
                clearAllFilters,
                clearFilter,
                page,
                setPage,
                query,
                setQuery,
                categorySlugs,
                tagsIDs,
                showBtn,
                setShowBtn,
                showLoader,
                setShowLoader,
                hasData,
                setHasData,
            }}
        >
            {children}
        </NewsArchiveCtx.Provider>
    );
};

export default NewsArchiveCtx;
