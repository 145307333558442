import HighCharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Supported chart types
type ArticleChartType = 'pie';

type SupportedSeriesOptions = Highcharts.SeriesPieOptions & { colorByPoint: boolean };

type SupportedSeriesData = Array<number | [string, number | null] | null | Highcharts.PointOptionsObject>;

export interface ArticleChartProps {
    type: ArticleChartType;
    source?: string;
    titletext?: string;
    subtitletext?: string;
    data?: string;
    title?: string;
    subtitle?: string;
    chart?: string;
    tooltip?: string;
    accessibility?: string;
    plotoptions?: string;
    series?: string;
}

const DEFAULT_OPTIONS: { [K in ArticleChartType]: Highcharts.Options } = Object.freeze({
    pie: {
        title: {
            text: '{title}',
            align: 'left',
            style: {
                fontSize: '22px',
                fontWeight: '900',
            },
        },
        subtitle: {
            text: '{subtitle}',
            align: 'left',
            style: {
                color: '#8A73E8',
                fontWeight: '700',
            },
        },
        chart: {
            type: 'pie',
            plotShadow: false,
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.percentage:.1f} %',
                },
                showInLegend: true,
            },
        },
        series: [
            {
                type: 'pie',
                name: 'Percentage',
                colorByPoint: true,
                data: [],
            },
        ],
    },
});

const parseJSONString = (jsonStr: string) => {
    try {
        return JSON.parse(jsonStr);
    } catch (err) {
        return undefined;
    }
};

class ArticleChartInstance {
    type: ArticleChartType;

    titleText?: string;

    subtitleText?: string;

    source?: string;

    data?: SupportedSeriesData;

    title?: Highcharts.TitleOptions;

    subtitle?: Highcharts.SubtitleOptions;

    chart?: Highcharts.ChartOptions;

    tooltip?: Highcharts.TooltipOptions;

    accessibility?: Highcharts.AccessibilityOptions;

    plotOptions?: HighCharts.PlotOptions;

    series?: HighCharts.Series;

    constructor(props: ArticleChartProps) {
        const {
            type = 'pie',
            source,
            titletext,
            subtitletext,
            data,
            title,
            subtitle,
            chart,
            tooltip,
            accessibility,
            plotoptions,
            series,
        } = props;

        this.type = type;
        this.source = source;
        this.titleText = titletext;
        this.subtitleText = subtitletext;

        if (data) this.data = parseJSONString(data) as SupportedSeriesData;
        if (title) this.title = parseJSONString(title) as Highcharts.TitleOptions | undefined;
        if (subtitle) this.subtitle = parseJSONString(subtitle) as Highcharts.SubtitleOptions | undefined;
        if (chart) this.chart = parseJSONString(chart) as Highcharts.ChartOptions | undefined;
        if (tooltip) this.tooltip = parseJSONString(tooltip) as Highcharts.TooltipOptions | undefined;
        if (accessibility)
            this.accessibility = parseJSONString(accessibility) as Highcharts.AccessibilityOptions | undefined;
        if (plotoptions) this.plotOptions = parseJSONString(plotoptions) as HighCharts.PlotOptions | undefined;
        if (series) this.series = parseJSONString(series) as HighCharts.Series | undefined;
    }

    get titleOptions(): Highcharts.TitleOptions | undefined {
        if (this.title) return this.title;

        const defaultTitle = JSON.parse(JSON.stringify(DEFAULT_OPTIONS[this.type].title));
        if (this.titleText) defaultTitle.text = this.titleText;

        return defaultTitle;
    }

    get subtitleOptions(): Highcharts.SubtitleOptions | undefined {
        if (this.subtitle) return this.subtitle;

        const defaultSubtitle = JSON.parse(JSON.stringify(DEFAULT_OPTIONS[this.type].subtitle));
        if (this.subtitleText) defaultSubtitle.text = this.subtitleText;

        return defaultSubtitle;
    }

    get chartOptions(): Highcharts.ChartOptions | undefined {
        return this.chart || DEFAULT_OPTIONS[this.type].chart;
    }

    get creditsOptions(): Highcharts.CreditsOptions {
        return {
            enabled: false,
        };
    }

    get tooltipOptions(): Highcharts.TooltipOptions | undefined {
        return this.tooltip || DEFAULT_OPTIONS[this.type].tooltip;
    }

    get accessibilityOptions(): Highcharts.AccessibilityOptions | undefined {
        return this.accessibility || DEFAULT_OPTIONS[this.type].accessibility;
    }

    get plotOptionsOptions(): HighCharts.PlotOptions | undefined {
        return this.plotOptions || DEFAULT_OPTIONS[this.type].plotOptions;
    }

    get seriesOptions(): Array<SupportedSeriesOptions> {
        const defaultSeries = JSON.parse(JSON.stringify(DEFAULT_OPTIONS[this.type].series));
        if (this.data) defaultSeries[0].data = this.data;
        return this.series || defaultSeries;
    }

    get options(): Highcharts.Options {
        return {
            title: this.titleOptions,
            subtitle: this.subtitleOptions,
            chart: this.chartOptions,
            credits: this.creditsOptions,
            tooltip: this.tooltipOptions,
            accessibility: this.accessibilityOptions,
            plotOptions: this.plotOptionsOptions,
            series: this.seriesOptions,
        };
    }
}

const LogoHorizontal = ({ className }: { className: string }) => {
    return (
        <svg className={className} viewBox="0 0 100 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1871_20963)">
                <path d="M9.22566 0.218063H7.36717V10.1026H9.22566V0.218063Z" fill="currentColor" />
                <path
                    d="M13.1753 3.03348C11.1242 3.03348 9.57327 4.55192 9.57327 6.6708C9.58119 7.38063 9.79938 8.07225 10.2004 8.65859C10.6013 9.24493 11.1672 9.69976 11.8267 9.96583C12.4862 10.2319 13.2098 10.2973 13.9064 10.1538C14.6031 10.0103 15.2416 9.66428 15.7416 9.15937C16.2416 8.65446 16.5807 8.0132 16.7163 7.31633C16.8519 6.61947 16.7778 5.89815 16.5034 5.24321C16.2291 4.58827 15.7666 4.02898 15.1744 3.63577C14.5822 3.24256 13.8866 3.033 13.1753 3.03348ZM13.1753 8.63756C12.2687 8.63756 11.4291 7.96774 11.4291 6.68414C11.4291 5.40054 12.2687 4.73339 13.1753 4.73339C14.0818 4.73339 14.9214 5.38986 14.9214 6.68414C14.9214 7.97842 14.0845 8.62422 13.1753 8.62422V8.63756Z"
                    fill="currentColor"
                />
                <path
                    d="M20.5557 8.6082C19.6064 8.6082 18.7962 7.9117 18.7962 6.67079C18.7962 5.42989 19.5984 4.7494 20.5424 4.7494C20.8913 4.73427 21.2345 4.84155 21.5125 5.05263C21.7904 5.26371 21.9855 5.56525 22.0639 5.90491L23.7272 5.34716C23.433 4.19166 22.3714 3.03348 20.4996 3.03348C18.502 3.03348 16.9377 4.55192 16.9377 6.67079C16.9254 7.14968 17.0103 7.6261 17.1874 8.07136C17.3644 8.51662 17.63 8.92149 17.968 9.2616C18.3061 9.6017 18.7096 9.86999 19.1544 10.0503C19.5991 10.2306 20.0758 10.3192 20.5557 10.3108C22.3848 10.3108 23.4625 9.13926 23.7646 7.99709L22.1281 7.46337C22.0329 7.80266 21.826 8.10009 21.5408 8.30776C21.2555 8.51544 20.9086 8.62126 20.5557 8.6082Z"
                    fill="currentColor"
                />
                <path
                    d="M39.0255 3.24427L37.7553 7.66348L36.2739 3.24427H34.3191L32.8243 7.64747L31.5541 3.24427H30.6824H29.5994H28.2516L25.877 5.79546V0.218063H24.0212V10.1026H25.877V8.29061L26.6445 7.48202L28.4629 10.1026H30.7385L27.9307 6.12903L29.8694 4.09555L31.7921 10.1026H33.6773L35.255 5.41918L36.8622 10.1026H38.718L40.8706 3.24427H39.0255Z"
                    fill="currentColor"
                />
                <path
                    d="M44.0797 3.03344C43.3563 3.02445 42.6466 3.23032 42.0407 3.6249C41.4349 4.01947 40.9603 4.58492 40.6774 5.24936C40.3944 5.91379 40.3158 6.64719 40.4516 7.35632C40.5873 8.06544 40.9314 8.71825 41.4399 9.23176C41.9484 9.74526 42.5984 10.0963 43.3074 10.2401C44.0163 10.384 44.7521 10.3142 45.4212 10.0397C46.0903 9.76517 46.6624 9.29828 47.0649 8.69839C47.4675 8.0985 47.6821 7.39271 47.6817 6.67076C47.6817 4.55188 46.1334 3.03344 44.0797 3.03344ZM44.0797 8.63752C43.1705 8.63752 42.3335 7.9677 42.3335 6.6841C42.3335 5.4005 43.1705 4.73335 44.0797 4.73335C44.9888 4.73335 45.8258 5.38983 45.8258 6.6841C45.8258 7.97838 44.9862 8.62418 44.0797 8.62418V8.63752Z"
                    fill="currentColor"
                />
                <path
                    d="M49.8342 4.26099V3.24159H48.0319V10.0999H49.9037V6.97764C49.9037 5.49923 50.7273 5.04023 51.6766 5.04023C51.8644 5.03964 52.0517 5.05842 52.2355 5.09627V3.22824C52.0965 3.2149 51.9547 3.20156 51.8023 3.20156C51.2033 3.18821 50.2513 3.35367 49.8342 4.26099Z"
                    fill="currentColor"
                />
                <path
                    d="M59.1801 3.24427H56.7494L54.3748 5.79546V0.218063H52.519V10.1026H54.3748V8.29061L55.1449 7.48202L56.9606 10.1026H59.2363L56.4285 6.12903L59.1801 3.24427Z"
                    fill="currentColor"
                />
                <path
                    d="M62.226 5.9476L61.2473 5.73678C60.8703 5.6674 60.6189 5.46992 60.6189 5.12567C60.6189 4.73338 61.0093 4.44251 61.4987 4.44251C61.7697 4.41911 62.0393 4.50181 62.2504 4.67312C62.4614 4.84442 62.5975 5.09092 62.6298 5.36051L64.1808 5.01359C64.0952 4.27438 63.4401 3.03348 61.4853 3.03348C60.0039 3.03348 58.9155 4.05022 58.9155 5.27778C58.9155 6.23848 59.5145 7.03373 60.8409 7.32727L61.75 7.53542C62.2849 7.6475 62.4908 7.89835 62.4908 8.20524C62.4908 8.56818 62.1966 8.88841 61.5816 8.88841C60.7793 8.88841 60.3675 8.38671 60.3248 7.84231L58.7203 8.1919C58.8032 8.97114 59.5225 10.3108 61.5842 10.3108C63.3839 10.3108 64.2583 9.16594 64.2583 8.05313C64.2637 7.04707 63.5791 6.22514 62.226 5.9476Z"
                    fill="currentColor"
                />
                <path opacity="0.3" d="M2.4762 5.15504H0V7.62617H2.4762V5.15504Z" fill="currentColor" />
                <path opacity="0.2" d="M2.4762 7.62349H0V10.0946H2.4762V7.62349Z" fill="currentColor" />
                <path opacity="0.8" d="M2.4762 0.212765H0V2.6839H2.4762V0.212765Z" fill="currentColor" />
                <path opacity="0.5" d="M2.4762 2.68389H0V5.15502H2.4762V2.68389Z" fill="currentColor" />
                <path
                    opacity="0.9"
                    d="M4.57275 0.228775C4.49402 0.216723 4.4144 0.211367 4.33476 0.212764H2.47627V2.68123H4.95247V0.327515C4.82863 0.284932 4.70168 0.251921 4.57275 0.228775Z"
                    fill="currentColor"
                />
                <path
                    d="M6.62105 2.68123C6.62054 2.16627 6.45969 1.66419 6.16076 1.24444C5.86183 0.824684 5.43958 0.508002 4.95242 0.338192V2.6919L6.62105 2.68123Z"
                    fill="currentColor"
                />
                <path
                    opacity="0.9"
                    d="M6.62105 2.68118H4.95242V5.01355C5.43808 4.84468 5.85933 4.52971 6.15816 4.11201C6.457 3.6943 6.61872 3.19441 6.62105 2.68118Z"
                    fill="currentColor"
                />
                <path
                    opacity="0.8"
                    d="M2.47626 2.68118V5.15232H4.13419C4.41254 5.16061 4.69002 5.11717 4.95246 5.02422V2.68118H2.47626Z"
                    fill="currentColor"
                />
                <path
                    opacity="0.5"
                    d="M4.63674 5.1524C4.56187 5.1524 4.48699 5.1524 4.40944 5.1524H2.47073V7.62353H4.94694V5.20311C4.84471 5.17973 4.7411 5.1628 4.63674 5.1524Z"
                    fill="currentColor"
                />
                <path
                    opacity="0.8"
                    d="M4.95242 5.2031V7.62353H6.89648C6.89662 7.05958 6.70408 6.51244 6.35065 6.0724C5.99722 5.63236 5.50401 5.32573 4.95242 5.2031Z"
                    fill="currentColor"
                />
                <path
                    opacity="0.3"
                    d="M2.47627 7.62349V10.0946H4.63693C4.74309 10.0845 4.84848 10.0675 4.95247 10.0439V7.62349H2.47627Z"
                    fill="currentColor"
                />
                <path
                    opacity="0.5"
                    d="M4.95242 10.0439C5.50401 9.9213 5.99722 9.61466 6.35065 9.17463C6.70408 8.73459 6.89662 8.18744 6.89648 7.62349H4.95242V10.0439Z"
                    fill="currentColor"
                />
                <path
                    d="M97.7056 10.4229H69.7855C69.1775 10.4229 68.5943 10.182 68.1641 9.75323C67.7339 9.32441 67.4919 8.74271 67.4912 8.13591V4.09831C67.4926 3.49173 67.7349 2.91044 68.1649 2.48178C68.595 2.05311 69.1777 1.81202 69.7855 1.81131H97.7056C98.3132 1.81202 98.8957 2.0532 99.3253 2.48194C99.755 2.91068 99.9966 3.49198 99.9973 4.09831V8.13591C99.9973 8.43625 99.9381 8.73364 99.8229 9.01111C99.7077 9.28858 99.5389 9.5407 99.3261 9.75307C99.1133 9.96543 98.8607 10.1339 98.5826 10.2488C98.3046 10.3638 98.0066 10.4229 97.7056 10.4229ZM69.7855 2.03814C69.5144 2.03814 69.246 2.09143 68.9955 2.19497C68.7451 2.2985 68.5175 2.45025 68.3258 2.64155C68.1341 2.83286 67.982 3.05997 67.8783 3.30992C67.7746 3.55987 67.7212 3.82777 67.7212 4.09831V8.13591C67.7212 8.40646 67.7746 8.67436 67.8783 8.92431C67.982 9.17426 68.1341 9.40137 68.3258 9.59267C68.5175 9.78398 68.7451 9.93573 68.9955 10.0393C69.246 10.1428 69.5144 10.1961 69.7855 10.1961H97.7056C98.2532 10.1961 98.7782 9.97903 99.1654 9.59267C99.5525 9.20632 99.77 8.6823 99.77 8.13591V4.09831C99.77 3.55192 99.5525 3.02791 99.1654 2.64155C98.7782 2.2552 98.2532 2.03814 97.7056 2.03814H69.7855Z"
                    fill="currentColor"
                />
                <path
                    d="M72.7271 7.98108H71.9837V3.81538H73.6496C74.5802 3.81538 75.1605 4.25037 75.1605 5.09365C75.1771 5.38013 75.0872 5.66266 74.9081 5.88717C74.729 6.11168 74.4733 6.26242 74.1898 6.31053L75.2594 7.98108H74.4117L73.3956 6.36657H72.7217L72.7271 7.98108ZM73.6095 5.72878C74.1443 5.72878 74.4278 5.49928 74.4278 5.09365C74.4278 4.68802 74.1443 4.45318 73.6095 4.45318H72.7271V5.72878H73.6095Z"
                    fill="currentColor"
                />
                <path
                    d="M75.4571 6.45997C75.4571 5.56866 76.0615 4.87749 76.9092 4.87749C77.7568 4.87749 78.2622 5.54464 78.2622 6.37992V6.61208H76.123C76.1738 7.14581 76.4893 7.4527 76.9252 7.4527C77.0812 7.46307 77.2362 7.42046 77.3648 7.33179C77.4935 7.24313 77.5883 7.11362 77.6338 6.96434L78.2328 7.19117C78.1317 7.45189 77.9511 7.67432 77.7165 7.82707C77.4818 7.97981 77.205 8.05516 76.9252 8.04246C76.0668 8.04513 75.4571 7.39932 75.4571 6.45997ZM76.1604 6.08904H77.5616C77.5654 6.00349 77.5508 5.91813 77.5188 5.83868C77.4868 5.75923 77.4382 5.68752 77.3761 5.62838C77.3141 5.56924 77.2401 5.52403 77.1591 5.49577C77.0781 5.46752 76.9919 5.45689 76.9065 5.46458C76.7276 5.46169 76.5538 5.52324 76.4167 5.63794C76.2797 5.75264 76.1887 5.9128 76.1604 6.08904Z"
                    fill="currentColor"
                />
                <path
                    d="M79.0192 6.97768C79.1131 7.12693 79.2431 7.25015 79.3974 7.33597C79.5516 7.42179 79.725 7.46744 79.9016 7.46871C80.1984 7.46871 80.4712 7.36463 80.4712 7.09243C80.4712 6.82024 80.2038 6.80422 79.7331 6.70549C79.2625 6.60675 78.6983 6.48399 78.6983 5.83552C78.6983 5.27778 79.1876 4.87215 79.8882 4.87215C80.1247 4.86257 80.36 4.90966 80.5745 5.00948C80.789 5.10931 80.9764 5.25896 81.121 5.4459L80.6503 5.87021C80.5647 5.73602 80.446 5.62603 80.3055 5.55079C80.165 5.47556 80.0075 5.4376 79.8481 5.44056C79.5647 5.44056 79.3775 5.56866 79.3775 5.77147C79.3775 5.97429 79.5994 6.03833 79.9818 6.11305C80.5006 6.22247 81.1504 6.33455 81.1504 7.02839C81.1504 7.64484 80.5862 8.03712 79.8963 8.03712C79.6281 8.05034 79.3607 7.99877 79.1167 7.88681C78.8728 7.77484 78.6595 7.60578 78.495 7.39399L79.0192 6.97768Z"
                    fill="currentColor"
                />
                <path
                    d="M81.4632 6.45997C81.4632 5.56866 82.0676 4.87749 82.9152 4.87749C83.7629 4.87749 84.2683 5.54464 84.2683 6.37992V6.61208H82.1291C82.1799 7.14581 82.4954 7.4527 82.9313 7.4527C83.0873 7.46307 83.2422 7.42046 83.3709 7.33179C83.4996 7.24313 83.5944 7.11362 83.6399 6.96434L84.2389 7.19117C84.1378 7.45189 83.9572 7.67432 83.7225 7.82707C83.4879 7.97981 83.2111 8.05516 82.9313 8.04246C82.0729 8.04513 81.4632 7.39932 81.4632 6.45997ZM82.1665 6.08904H83.565C83.5688 6.00349 83.5542 5.91813 83.5222 5.83868C83.4902 5.75923 83.4416 5.68752 83.3795 5.62838C83.3175 5.56924 83.2435 5.52403 83.1625 5.49577C83.0815 5.46752 82.9953 5.45689 82.9099 5.46458C82.7315 5.46234 82.5583 5.52418 82.4218 5.63882C82.2853 5.75346 82.1947 5.91326 82.1665 6.08904Z"
                    fill="currentColor"
                />
                <path
                    d="M84.5706 7.13247C84.5706 6.55338 85.0065 6.2972 85.5948 6.17711L86.4371 6.00365V5.95028C86.4371 5.6594 86.2847 5.4806 85.9023 5.4806C85.7661 5.46587 85.6294 5.50388 85.5204 5.58681C85.4114 5.66974 85.3385 5.79124 85.3167 5.92626L84.6589 5.77682C84.7398 5.50653 84.9092 5.27109 85.1399 5.10808C85.3706 4.94507 85.6494 4.86389 85.9317 4.8775C86.6644 4.8775 87.1057 5.22441 87.1057 5.92626V7.24455C87.1057 7.42068 87.1805 7.47138 87.3731 7.45003V7.98376C86.8677 8.0398 86.6003 7.94106 86.496 7.69288C86.377 7.80968 86.235 7.90039 86.0789 7.95922C85.9228 8.01804 85.7561 8.04367 85.5895 8.03446C85.0172 8.04513 84.5706 7.67954 84.5706 7.13247ZM86.4425 6.53737L85.7847 6.67614C85.4878 6.74019 85.2686 6.83092 85.2686 7.10312C85.2686 7.37531 85.4424 7.47405 85.7098 7.47405C86.0815 7.47405 86.4425 7.27924 86.4425 6.90831V6.53737Z"
                    fill="currentColor"
                />
                <path
                    d="M89.5791 5.62471C89.4949 5.61372 89.41 5.60837 89.325 5.6087C89.2214 5.60172 89.1174 5.61681 89.0201 5.65299C88.9227 5.68917 88.8342 5.7456 88.7603 5.81854C88.6865 5.89147 88.629 5.97925 88.5917 6.07603C88.5545 6.17282 88.5383 6.27641 88.5442 6.37992V7.98109H87.8489V4.93621H88.5442V5.37653C88.6244 5.22682 88.7458 5.10309 88.8941 5.01989C89.0424 4.93668 89.2114 4.89747 89.3812 4.90685C89.4471 4.90173 89.5132 4.90173 89.5791 4.90685V5.62471Z"
                    fill="currentColor"
                />
                <path
                    d="M91.3066 7.44203C91.4714 7.4422 91.6308 7.38371 91.7563 7.27708C91.8818 7.17045 91.965 7.02267 91.9911 6.86027L92.5901 7.16183C92.501 7.42777 92.3275 7.65754 92.096 7.81641C91.8644 7.97529 91.5873 8.05463 91.3066 8.04247C90.4348 8.04247 89.8064 7.39933 89.8064 6.45998C89.8064 5.52063 90.4348 4.8775 91.3066 4.8775C91.5833 4.86407 91.857 4.94056 92.0865 5.09549C92.316 5.25042 92.4889 5.47544 92.5794 5.73679L91.9911 6.05435C91.964 5.89268 91.8803 5.74583 91.755 5.63983C91.6297 5.53383 91.4708 5.47553 91.3066 5.47527C90.8306 5.47527 90.5043 5.84086 90.5043 6.45998C90.5043 7.0791 90.8306 7.44203 91.3066 7.44203Z"
                    fill="currentColor"
                />
                <path
                    d="M92.9993 3.81538H93.6973V5.26177C93.7998 5.13882 93.9288 5.04044 94.0746 4.97393C94.2204 4.90741 94.3793 4.87445 94.5396 4.87749C95.1787 4.87749 95.5611 5.31781 95.5611 5.97429V7.98108H94.8632V6.1771C94.8632 5.80083 94.7134 5.52596 94.3284 5.52596C94.2422 5.52581 94.157 5.54325 94.0779 5.57721C93.9988 5.61117 93.9275 5.66093 93.8683 5.72343C93.8092 5.78593 93.7635 5.85984 93.7341 5.94061C93.7047 6.02139 93.6922 6.10732 93.6973 6.19311V7.98108H92.9993V3.81538Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_1871_20963">
                    <rect width="100" height="10.2128" fill="white" transform="translate(0 0.212765)" />
                </clipPath>
            </defs>
        </svg>
    );
};

const ArticleChart = (props: ArticleChartProps): JSX.Element => {
    const { options, source } = new ArticleChartInstance(props);

    return (
        <div className="my-6">
            <HighchartsReact highcharts={HighCharts} options={options} />
            <div className="w-full p-3 flex flex-row justify-between">
                <LogoHorizontal className="h-6 w-auto text-black" />
                {source && (
                    <div className="w-1/2 flex flex-row justify-end">
                        <span className="truncate">{`Source: ${source}`}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ArticleChart;
