export type ContainerDef = {
    id: string;
    slot: string;
    sizes: [number, number][] | [number, number];
    options?: {
        collapse?: boolean;
        collapseBeforeAdFetch?: boolean;
        label?: boolean;
    };
};

// Returns an object with typesafe keys for enum usage.
const registerAdContainers = <T extends Record<string, ContainerDef>>(containers: T) => containers;

const AdContainerIds = registerAdContainers({
    /*
    // Legacy ad slots
    NewsArticleRight600x300: {
        id: 'div-gpt-ad-1654526180790-0',
        slot: '/22757941429/Article_DSK_300x600',
        sizes: [300, 600],
    },
    NewsArticleMidArticle300x250: {
        id: 'div-gpt-ad-1654526430259-0',
        slot: '/22757941429/Article_MOB_300x250',
        sizes: [300, 250],
    },
*/
    // New ad slots
    NewsArticleRight600x300: {
        id: 'div-gpt-ad-1667357921633-0',
        slot: '/22757941429/ROS_desktop_articles_rightrail',
        sizes: [
            [300, 250],
            [300, 600],
        ],
    },
    NewsArticleMidArticle300x250: {
        id: 'div-gpt-ad-1667358047580-0',
        slot: '/22757941429/ROS_mobile_articles_inContent1',
        sizes: [300, 250],
    },
    CategoryRight300x250: {
        id: 'div-gpt-ad-1667347317141-0',
        slot: '/22757941429/ROS_desktop_categoryPages_rightrail',
        sizes: [
            [300, 600],
            [300, 250],
        ],
    },
    CategoryBetweenStory3And4300x250: {
        id: 'div-gpt-ad-1667348040636-0',
        slot: '/22757941429/ROS_mobile_categoryPages_inContent1',
        sizes: [300, 250],
    },
    TagRight300x250: {
        id: 'div-gpt-ad-1667357280720-0',
        slot: '/22757941429/ROS_desktop_tagPages_rightrail',
        sizes: [
            [300, 250],
            [300, 600],
        ],
    },
    TagBetweenStory3And4300x250: {
        id: 'div-gpt-ad-1667357388912-0',
        slot: '/22757941429/ROS_mobile_tagPages_inContent1',
        sizes: [300, 250],
    },
    TagBetweenStory6And7300x250: {
        id: 'div-gpt-ad-1667357540438-0',
        slot: '/22757941429/ROS_mobile_tagPages_inContent2',
        sizes: [300, 250],
    },
    Ticker120x20: {
        id: 'div-gpt-ad-1667257780018-0',
        slot: '/22757941429/ticker',
        sizes: [120, 20],
        options: {
            label: false,
        },
    },
    HomepageDesktop300x250: {
        id: 'div-gpt-ad-1683112648791-0',
        slot: '/22757941429/Homepage_DSK_300x250',
        sizes: [300, 250],
        options: {
            collapseBeforeAdFetch: false,
        },
    },
    HomepageMobile300x250: {
        id: 'div-gpt-ad-1683112782771-0',
        slot: '/22757941429/Homepage_MOB_300x250',
        sizes: [300, 250],
    },
    // not yet defined
    // NewsArticleLeft300x300: {
    //     id: '',
    //     slot: '',
    //     sizes: [],
    // },
    // CategoryBetweenStory6And7300x250: {
    //     id: '',
    //     slot: '',
    //     sizes: [],
    // },
});

export default AdContainerIds;
