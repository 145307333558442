import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

type TickerItemProps = {
    name: string;
    value: number | null;
    percentage: number | null;
    className: string;
};

const TickerItem = (props: TickerItemProps) => {
    const { name, value, percentage, className } = props;

    if (percentage === null) {
        return null;
    }

    return (
        <div className={cn(className, 'flex justify-center items-center relative gap-1')}>
            <p className="text-xs uppercase">{name}</p>
            <p className="text-xs uppercase">{value !== null ? value?.toFixed(2) : ''}</p>
            {percentage >= 0 && <p className="text-xs uppercase text-[#147d51]">{percentage?.toFixed(2)}%</p>}
            {percentage < 0 && <p className="text-xs uppercase text-[#880c0c]">{percentage?.toFixed(2)}%</p>}
        </div>
    );
};

export default TickerItem;
