import { useEffect, useRef, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { useTrack } from '@blockworks/platform/services/analytics';
import { Link } from '@blockworks/ui/legacy/link';

import { useAudioPlayer } from '@/components/pages/podcast/layout/audio-provider';
import { ForwardButton } from '@/components/player/forward-button';
import { MuteButton } from '@/components/player/mute-button';
import { PlayButton } from '@/components/player/play-button';
import { PlaybackRateButton } from '@/components/player/playback-rate-button';
import { RewindButton } from '@/components/player/rewind-button';
import { Slider } from '@/components/player/slider';

const parseTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    seconds = seconds - hours * 3600 - minutes * 60;
    return [hours, minutes, seconds];
};

const formatHumanTime = (seconds: number) => {
    const [h, m, s] = parseTime(seconds);
    return `${h} hour${h === 1 ? '' : 's'}, ${m} minute${m === 1 ? '' : 's'}, ${s} second${s === 1 ? '' : 's'}`;
};

export const AudioPlayer = () => {
    const track = useTrack();
    const player = useAudioPlayer({});
    const wasPlayingRef = useRef(false);

    const [currentTime, setCurrentTime] = useState(player.currentTime);

    useEffect(() => {
        setCurrentTime(null);
    }, [player.currentTime]);

    if (!player.meta) {
        return null;
    }

    const trackInteraction = (type: string, props: any = {}) => {
        track('Audio Player Interaction', {
            type,
            state: player.playing ? 'playing' : 'paused',
            currentTime,
            ...player.meta,
            ...props,
        });
    };

    const closePlayer = () => {
        player.stop();

        track('Audio Player Closed', {
            state: 'stopped',
            currentTime,
            ...player.meta,
        });
    };

    return (
        <div className="flex relative items-center gap-6 bg-white/80 py-4 px-4 border border-l-0 border-r-0 backdrop-blur-sm md:px-6 mx-auto w-full">
            <button
                className="absolute text-xs flex items-center align-middle right-2 top-2 rounded-md p-1 text-slate-500 hover:bg-slate-100 hover:text-slate-700"
                onClick={() => closePlayer()}
            >
                <XMarkIcon className="w-4 h-4" /> CLOSE
            </button>
            <div className="hidden md:block">
                <PlayButton
                    player={player}
                    size="medium"
                    onClick={() => trackInteraction(player.playing ? 'pause' : 'play')}
                />
            </div>
            <div className="mb-[env(safe-area-inset-bottom)] flex flex-1 flex-col gap-3 overflow-hidden p-1">
                <Link
                    href={player.meta.link || ''}
                    title={player.meta.title}
                    className="truncate text-center text-sm font-bold leading-6 md:text-left"
                >
                    {player.meta.title}
                </Link>
                <div className="flex justify-between gap-6">
                    <div className="flex items-center md:hidden">
                        <MuteButton player={player} />
                    </div>
                    <div className="flex flex-none items-center gap-4">
                        <RewindButton player={player} onClick={() => trackInteraction('rewind')} />
                        <div className="md:hidden">
                            <PlayButton
                                player={player}
                                size="small"
                                onClick={() => trackInteraction(player.playing ? 'pause' : 'play')}
                            />
                        </div>
                        <ForwardButton player={player} onClick={() => trackInteraction('forward')} />
                    </div>
                    <Slider
                        label="Current time"
                        maxValue={player.duration}
                        step={1}
                        value={[currentTime ?? player.currentTime]}
                        onChange={([v]: any[]) => setCurrentTime(v)}
                        onChangeEnd={(value: any) => {
                            player.seek(value);
                            if (wasPlayingRef.current) {
                                player.play();
                            }

                            trackInteraction('seek');
                        }}
                        numberFormatter={{ format: formatHumanTime }}
                        onChangeStart={() => {
                            wasPlayingRef.current = player.playing;
                            player.pause();
                        }}
                    />
                    <div className="flex items-center gap-4">
                        <div className="flex items-center">
                            <PlaybackRateButton
                                player={player}
                                onClick={() => trackInteraction('playback rate', { rate: player.getPlaybackRate() })}
                            />
                        </div>
                        <div className="hidden items-center md:flex">
                            <MuteButton player={player} onClick={() => trackInteraction('muted')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
