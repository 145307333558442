import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const spinnerStyles = tVariants({
    base: 'animate-spin inline',
    defaultVariants: {
        size: 5,
    },
    variants: {
        color: {
            white: 'text-white',
            purple: 'text-primary',
        },
        size: {
            4: 'w-4 h-4',
            5: 'w-5 h-5',
            6: 'w-6 h-6',
            7: 'w-7 h-7',
            8: 'w-8 h-8',
            9: 'w-9 h-9',
            10: 'w-10 h-10',
        },
        ...factoryStyleProps.__atoms.space.margin,
    },
});

export { spinnerStyles };
