import { TwitterTweetEmbed } from 'react-twitter-embed';

type TwitterEmbedProps = {
    tweetUrl: string;
};

const TwitterEmbed = (props: TwitterEmbedProps) => {
    const { tweetUrl } = props;

    const tweetId = tweetUrl.substring((tweetUrl.lastIndexOf('/') || 0) + 1).split('?')[0];

    if (!tweetId) {
        return null;
    }

    return <TwitterTweetEmbed tweetId={tweetId} placeholder={<span>Loading Tweet..</span>} />;
};

export default TwitterEmbed;
