import { useMemo } from 'react';
import { Pluggable } from 'unified';

import { ComponentOverrides, useAbstractProcessor } from './use-abstract-processor';

/**
 * Hook to convert markdown into react fragments
 *
 * @param markdown
 * @param components
 * @param plugins
 */

const useMarkdownProcessor = <T extends Pluggable[], K extends ComponentOverrides>(
    markdown: string | null,
    components?: K,
    plugins?: T,
) => {
    const { renderSync } = useAbstractProcessor('markdown', components, plugins);

    return useMemo(() => renderSync(markdown), [markdown]);
};

export { useMarkdownProcessor };
