import React, { useCallback, useState } from 'react';
import { PluggableList, unified } from 'unified';

import htmlPreset from '../rehype-presets/html';
import markdownPreset from '../rehype-presets/markdown';

const presets = {
    markdown: markdownPreset,
    html: htmlPreset,
};

export interface RenderResult {
    content: React.ReactNode;
    isRendered: boolean;
}
export interface ComponentOverrides {
    [key: string]: <T extends {}>(props: T) => React.ReactNode;
}

const useAbstractProcessor = <T extends PluggableList, K extends ComponentOverrides>(
    type: keyof typeof presets,
    components?: K,
    plugins?: T,
) => {
    const [processor] = useState(() => {
        let renderAst = unified().use(presets[type](components));

        if (plugins) {
            renderAst = renderAst.use(plugins);
        }
        return renderAst;
    });

    const renderSync = useCallback(
        (rawContent: string | null): RenderResult => {
            if (!rawContent) {
                return {
                    isRendered: false,
                    content: null,
                };
            }

            try {
                const { result } = processor.processSync(rawContent);

                if (React.isValidElement(result)) {
                    return {
                        isRendered: true,
                        content: result,
                    };
                }
                return {
                    isRendered: false,
                    content: (
                        <div>
                            <div>There was an error processing the article's content.</div>
                        </div>
                    ),
                };
            } catch (err) {
                return {
                    isRendered: false,
                    content: (
                        <div>
                            <div>There was an error processing the article's content.</div>
                            <p className="text-red-500">{`${err}`}</p>
                        </div>
                    ),
                };
            }
        },
        [processor],
    );

    const renderAsync = useCallback(
        async (rawContent: string | null) => {
            if (!rawContent) {
                return {
                    isRendered: false,
                    content: null,
                };
            }

            try {
                const { result } = await processor.process(rawContent);

                if (React.isValidElement(result)) {
                    return {
                        isRendered: true,
                        content: result,
                    };
                }
                return {
                    isRendered: false,
                    content: (
                        <div>
                            <div>There was an error processing the article's content.</div>
                        </div>
                    ),
                };
            } catch (err) {
                return {
                    isRendered: false,
                    content: (
                        <div>
                            <div>There was an error processing the article's content.</div>
                            <p className="text-red-500">{`${err}`}</p>
                        </div>
                    ),
                };
            }
        },
        [processor],
    );

    return { renderSync, renderAsync };
};

export { useAbstractProcessor };
