// @ts-nocheck
import Script from 'next/script';

type GoogleAdsScriptProps = {
    onLoad?: () => void;
};

const GoogleAdsScript = ({ onLoad }: GoogleAdsScriptProps) => {
    if (!process.env.GOOGLE_ADS_CLIENT_ID) {
        return null;
    }

    return (
        <>
            <Script
                src={`https://securepubads.g.doubleclick.net/tag/js/gpt.js?client=${process.env.GOOGLE_ADS_CLIENT_ID}`}
                data-ad-client={process.env.GOOGLE_ADS_CLIENT_ID}
                strategy={'lazyOnload'}
                async
                onLoad={onLoad}
            />
        </>
    );
};

export default GoogleAdsScript;
