import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

type IExpandableImage = {
    children: JSX.Element | React.ReactNode;
};

const ExpandableImage = (props: IExpandableImage) => {
    const { children } = props;

    const [open, setOpen] = useState(false);

    return (
        <span className="block">
            <span onClick={() => setOpen(true)} className="hover:cursor-pointer align-center block">
                {children}
            </span>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10 block" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto m-4">
                        <div className="flex items-center justify-center min-h-full text-center align-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-xl overflow-hidden shadow-xl transform transition-all w-full flex flex-col max-w-7xl">
                                    <div className="absolute top-2 right-2 lg:top-4 lg:right-4">
                                        <XMarkIcon
                                            className="h-5 w-5 text-gray-400 hover:cursor-pointer"
                                            onClick={() => setOpen(false)}
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="p-6 lg:p-12 flex justify-center text-center">{children}</div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </span>
    );
};

export { ExpandableImage };
