const PlatformConfig = {
    locale: 'en',
    timezone: 'America/New_York',
    BEEHIIV_API_KEY: process.env.BEEHIIV_API_KEY,
    MIXPANEL_API_KEY: process.env.MIXPANEL_API_KEY,
    ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID ?? '',
    ALGOLIA_KEY: process.env.ALGOLIA_KEY ?? '',
    ALGOLIA_WRITE_KEY: process.env.ALGOLIA_WRITE_KEY ?? '',
    SITE_URL: process.env.SITE_URL!,
    STRAPI_API_URL: process.env.STRAPI_URL,
    STRAPI_TOKEN: process.env.STRAPI_TOKEN,
    GRIP_TOKEN: process.env.GRIP_TOKEN,
    isProd: process.env.NODE_ENV === 'production',
    isDev: process.env.NODE_ENV === 'development',
};

export { PlatformConfig };
