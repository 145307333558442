const replaceCmsUrl = (html?: string | null): string => {
    if (html === undefined) {
        return '';
    }
    if (html?.replace) {
        return html.replace(/cms\.blockworks\.co/g, 'blockworks.co');
    }
    return html || '';
};

export default replaceCmsUrl;
