import { cn } from '@blockworks/ui/utils';

import { ContainerDef } from '@/components/ads/ad-container-ids';
import useAdSlot from '@/components/ads/hooks/use-ad-slot';

type EventCardProps = {
    className?: string;
    googleAd: ContainerDef;
    children?: React.ReactNode;
};

const defaultOptions = { collapse: true, collapseBeforeAdFetch: true, label: true };

const AdContainer = ({ children, className, googleAd }: EventCardProps) => {
    const { options = {} } = googleAd;
    const slotOptions = { ...defaultOptions, ...options };
    const { label } = slotOptions;

    const attrs = useAdSlot(googleAd);
    return (
        <ins {...attrs} className={cn('ad-slot', label ? 'ad-slot-label' : null, className)}>
            <>{children}</>
        </ins>
    );
};

export default AdContainer;
