import rehypeParse from 'rehype-parse';
import { Preset } from 'unified';

import commonPreset from './common';

const htmlPreset = (...options: Parameters<typeof commonPreset>): Preset => {
    return {
        plugins: [[rehypeParse, { fragment: true }], commonPreset(...options)],
    };
};

export default htmlPreset;
