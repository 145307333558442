import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

import NewsletterSubscribeInput from '@/components/pages/frontpage/components/newsletter-subscribe-input';
import subscribeToNewsletter from '@/utils/functions/subscribe-to-newsletter';

type FooterNewsletterProps = {
    className?: string;
};

const FooterNewsletter = (props: FooterNewsletterProps) => {
    const { className } = props;

    return (
        <div
            className={cn(
                'flex flex-col justify-start items-start self-stretch flex-grow gap-3 p-10 bg-[#110b29]',
                className,
            )}
        >
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2">
                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                        <p className="flex-grow-0 flex-shrink-0 text-[10px] text-left uppercase text-[#f7f7f7]">
                            newsletter
                        </p>
                    </div>
                </div>
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                    <p className="flex-grow text-xl text-left text-white">Blockworks Daily Newsletter</p>
                </div>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow text-sm text-left text-white">
                    Get the daily newsletter that helps thousands of investors understand the markets.
                </p>
            </div>
            <NewsletterSubscribeInput
                inputPlaceholder="Email address"
                buttonText="subscribe"
                source="footer"
                onButtonClick={subscribeToNewsletter}
            />
        </div>
    );
};

export default FooterNewsletter;
