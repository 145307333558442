import { HTMLProps, useEffect, useMemo, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

import { sleep } from '@blockworks/platform/utils';

import { Spinner } from '../../../components/spinner';
import styled from '../functions/styled';

const customCss = styled.minify`
    .react-grid-item:has(div[class*='parameters']),
    .react-grid-item:has(div[class*='InlineParameterField']) {
        display: none;
    }

    .react-grid-layout:has(div[class*='parameters']),
    .react-grid-layout:has(div[class*='InlineParameterField']) {
        top: -5rem;
    }

    section[class^='grid'] {
        padding: 0 !important;
    }

    .react-grid-item div[class^='visual_wrap'] {
        padding: 1rem 2rem 1rem;
    }

    ul[class^='table_footer'],
    .react-grid-item div[class^='visual_wrap'] {
        background: rgb(249, 250, 251);
    }

    .react-grid-item div[class^='visual_counter'],
    .react-grid-item div[class^='visual_wrap'] {
        border: 1px solid rgb(229, 231, 235);
        border-radius: 0.5rem;
    }

    div[class*='visual_counter'] svg {
        fill: black !important;
    }

    table[class^='table'],
    div[class^='visual_vizFooter'] > ul[class^='table_footer'] * {
        font-family: inherit !important;
    }

    table[class^='table'] th {
        background-color: rgb(249 250 251) !important;
    }

    table[class^='table'] th:after {
        content: '';
        border-bottom: 2px solid var(--gray-700);
        width: calc(100% + 20px);
        display: block;
        position: relative;
        left: -10px;
        top: 4px;
    }

    table[class^='table'] td {
        border-bottom-color: rgb(229, 231, 235);
    }

    div[class^='tooltip'],
    ul[class^='table_footer'] li > * {
        background-color: white !important;
        border: 1px solid rgb(229, 231, 235);
    }
    .visx-tooltip[class*='tooltip_tooltip'] {
        background-color: rgb(249 250 251) !important;
        border: 1px solid var(--blue-300);
    }

    div[class^='visual_wrap'] header[class^='header_header'] {
        position: relative;
        container: chartheader / inline-size;
    }

    div[class^='visual_wrap']:has(figure[class^='chart_chart']) header[class^='header_header']:before {
        content: '';
        background-image: url(${process.env.SITE_URL}/images/logo@2x.png);
        background-size: cover;
        background-repeat: no-repeat;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 0;
        pointer-events: none;
        opacity: 0.7;
        height: 16px;
        aspect-ratio: 450/47;
    }

    @container chartheader (width < 400px) {
        div[class^='visual_wrap']:has(figure[class^='chart_chart']) header[class^='header_header']:before {
            display: none;
        }
    }

    a[class^='markdown_markdownAnchor'],
    div[class^='visual_ownerStatuses'],
    span[class^='status_icon'],
    img[class^='chart_watermark'],
    img[class^='visual_watermark'],
    header[class^='pagetitle'],
    span[class^='status_status'],
    div[class^='header_details'] > div > a > span:nth-child(2),
    div[class^='header_owner'],
    div[class^='header_link'] > span:nth-child(1) {
        display: none !important;
    }

    a,
    a > * {
        pointer-events: none;
        cursor: default;
        text-decoration: none !important;
    }

    table[class^='table'] a {
        pointer-events: auto;
        cursor: pointer;
    }
`;

export interface DuneEmbedProps extends HTMLProps<HTMLIFrameElement> {}

const DuneEmbed = ({ src, height = '100%' }: DuneEmbedProps) => {
    const [activeSrc, setActiveSrc] = useState(src);
    const [frameStatus, setFrameStatus] = useState<'loading' | 'loaded'>('loading');
    const embedRef = useRef<HTMLIFrameElement>(undefined!);

    useEffect(() => {
        let cancelled = false;
        sleep(500).then(() => {
            if (cancelled) return;
            setActiveSrc(prev => {
                if (prev === src) return prev;
                setFrameStatus('loading');
                return src;
            });
        });
        return () => {
            cancelled = true;
        };
    }, [src]);

    const iframeSrc = useMemo(() => {
        if (!activeSrc) return undefined;
        const url = new URL(activeSrc);
        url.searchParams.set('customCss', customCss);
        return url.toString();
    }, [activeSrc]);

    if (!iframeSrc) return <></>;

    return (
        <div className="relative" style={{ height }}>
            <Transition
                appear
                show={activeSrc === src}
                enter="transition-opacity ease-in delay-1000 duration-200"
                leave="transition-opacity ease-in duration-200"
                enterFrom="opacity-0"
                enterTo="opacty-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <iframe
                    ref={embedRef}
                    src={iframeSrc}
                    onLoad={async () => {
                        await sleep(600);
                        if (embedRef.current?.src === iframeSrc) setFrameStatus('loaded');
                    }}
                    height={height}
                    width={frameStatus === 'loaded' || activeSrc !== src ? '100%' : '99%'}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </Transition>
            <Transition
                show={activeSrc !== src || frameStatus === 'loading'}
                appear
                enter="transition-opacity ease-in duration-400"
                leave="transition-opacity ease-in duration-400"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="absolute inset-0 flex items-center flex-col pt-16">
                    <Spinner className="w-[64px] h-[64px]" />
                </div>
            </Transition>
        </div>
    );
};

export default DuneEmbed;
