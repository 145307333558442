import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { Preset } from 'unified';

import commonPreset from './common';

const markdownPreset = (...options: Parameters<typeof commonPreset>) => {
    return {
        plugins: [
            [remarkParse, { allowDangerousHtml: true }], // This is required for our own custom component tags
            remarkGfm,
            [remarkRehype, { allowDangerousHtml: true }],
            rehypeRaw,
            commonPreset(...options),
        ],
    } as Preset;
};

export default markdownPreset;
