import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

import FooterNewsletter from '@/layout/containers/default-container/footer/footer-newsletter';
import FooterResearchTeaser from '@/layout/containers/default-container/footer/footer-research-teaser';

type FooterTopSectionProps = {
    className?: string;
};

const FooterTopSection = (props: FooterTopSectionProps) => {
    const { className } = props;

    return (
        <div className={cn('flex flex-wrap sm:flex-nowrap justify-center border-b border-[#b4b4b4]/[0.35]', className)}>
            <FooterNewsletter />
            <FooterResearchTeaser />
        </div>
    );
};

export default FooterTopSection;
