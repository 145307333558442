import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Router from 'next/router';

import { ContainerDef } from '@/components/ads/ad-container-ids';
import GoogleAdsScript from '@/components/scripts/google-ads-script';

type AdsStatus = 'loading' | 'loaded' | 'ready' | 'transitioning' | 'reset';

interface AdsContextValue {
    status: AdsStatus;
    destroySlot: (adContainer: ContainerDef) => void;
    defineAdSlot: (adContainer: ContainerDef) => void;
    slotMap: Map<string, googletag.Slot>;
    // displayAd: (adContainer: ContainerDef) => void;
}

const AdsContext = React.createContext<AdsContextValue>(undefined!);

// Create an analytics hook that we can use with other components.
export const useAds = () => {
    const result = React.useContext(AdsContext);

    if (!result) {
        throw new Error('Context used outside of its Provider!');
    }

    return result;
};

const poll = (cb: () => boolean | undefined, timeout: number = 50) => {
    const shouldExit = cb();
    if (shouldExit) return;
    setTimeout(() => {
        poll(cb, timeout);
    }, timeout);
};

const tagExists = () => typeof window !== 'undefined' && !!window.googletag;

const AdsProvider = ({ children }: { children?: React.ReactNode | JSX.Element | JSX.Element[] }) => {
    const slotMapRef = useRef(new Map<string, googletag.Slot>());
    const [status, setStatus] = useState<AdsStatus>('loading');

    useEffect(() => {
        if (typeof window === 'undefined') return;

        if (status === 'loading') {
            poll(() => {
                const tagReady = tagExists();
                if (tagReady) setStatus('loaded');
                return tagReady;
            });
        }
        if (status === 'loaded') {
            googletag?.cmd.push(() => {
                googletag?.pubads().enableSingleRequest();
                googletag?.pubads().collapseEmptyDivs(true);
                googletag?.pubads().disableInitialLoad();
            });
            setStatus('ready');
        }

        if (status === 'ready') {
            googletag?.cmd.push(() => {
                googletag?.pubads().refresh();
            });
        }

        if (status === 'reset') {
            googletag?.cmd.push(() => {
                googletag?.pubads().clear();
            });
            setStatus('ready');
        }
    }, [status]);

    const defineAdSlot: AdsContextValue['defineAdSlot'] = useCallback(adUnit => {
        // If tag doesn't exist yet push events to cmd queue to be executed later.
        if (!tagExists() || status === 'ready' || slotMapRef.current.has(adUnit.id)) return;

        googletag?.cmd.push(() => {
            const { slot, sizes, id, options: { collapse = true, collapseBeforeAdFetch = true } = {} } = adUnit;

            const slotDefinition = googletag
                ?.defineSlot(slot, sizes, id)
                ?.setCollapseEmptyDiv(collapse, collapseBeforeAdFetch)
                ?.addService(googletag.pubads());

            googletag.enableServices();
            googletag.display(adUnit.id);

            slotMapRef.current.set(id, slotDefinition!);
        });
    }, []);

    const destroySlot: AdsContextValue['destroySlot'] = useCallback(adUnit => {
        if (!tagExists() || !slotMapRef.current.has(adUnit.id)) return;
        const slotDef = slotMapRef.current.get(adUnit.id);

        slotMapRef.current.delete(adUnit.id);

        googletag.cmd.push(() => {
            googletag.destroySlots([slotDef!]);
        });
    }, []);

    useEffect(() => {
        const startTransition = () =>
            setStatus(prev => (['loading', 'loaded'].includes(prev) ? prev : 'transitioning'));
        const endTransition = () => setStatus(prev => (prev === 'transitioning' ? 'reset' : prev));

        Router.events.on('routeChangeStart', startTransition);
        Router.events.on('routeChangeComplete', endTransition);
        return () => {
            Router.events.off('routeChangeStart', startTransition);
            Router.events.off('routeChangeComplete', endTransition);
        };
    }, []);

    const ctx = useMemo(
        () => ({
            destroySlot,
            status,
            defineAdSlot,
            slotMap: slotMapRef.current,
        }),
        [status, destroySlot, defineAdSlot],
    );

    return (
        <>
            <AdsContext.Provider value={ctx}>{children as React.ReactNode}</AdsContext.Provider>
            <GoogleAdsScript />
        </>
    );
};

export default AdsProvider;
