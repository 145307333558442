import { AnalyticsInstance } from 'analytics';

import { IMetaProps } from '@/layout/head/default-meta';

/*
interface PageEventProps {
    event: string;
    page: string;
}
*/

const onPageView = (url: string, analytics: AnalyticsInstance, meta?: IMetaProps) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`onPageView: ${url}`, meta);
    }

    if (meta?.authors || meta?.tags || meta?.categories) {
        analytics.page({
            title: meta?.title ?? undefined,
            author: (meta?.authors || []).join(','),
            authors: meta?.authors || [],
            tags: meta?.tags || [],
            categories: meta?.categories || [],
            article_modified_time: meta?.article_modified_time ?? undefined,
            article_published_time: meta?.article_published_time ?? undefined,
        });

        return;
    }

    // Track page without any properties
    analytics.page({ url });
};

export default onPageView;
