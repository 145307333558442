import * as production from 'react/jsx-runtime';
import { ImageProps } from 'next/image';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeReact, { Options } from 'rehype-react';
import rehypeSlug from 'rehype-slug';
import { Preset } from 'unified';

import { ExpandableImage } from '../../components/expandable-image';
import { Link } from '../../components/link';
import DuneEmbed from '../rehype-plugins/dune-embed';
import TwitterEmbed from '../rehype-plugins/twitter-embed';

import ArticleChart, { ArticleChartProps } from './article-chart';

const defaultOptions = {
    passNode: true,
    components: {
        img: (props: ImageProps) => {
            return (
                <ExpandableImage>
                    <img src={props.src as string} className={props.className} alt={props.alt} />
                </ExpandableImage>
            );
        },
        iframe: (props: any) => {
            if (!props.src) {
                return null;
            }

            return (
                <iframe
                    src={props.src}
                    width="100%" // Always use 100% width for iframes to not break the layout
                    height={props.height || '100%'}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            );
        },
        blockquote: (props: any) => {
            if (props.children instanceof Array) {
                const twitterLink = props.children.find(
                    (c: any) =>
                        (c.type?.name === 'a' || c.type === 'a') &&
                        (c.props?.href?.includes('twitter.com') || c.props?.href?.includes('x.com')),
                );

                if (twitterLink && twitterLink.props?.href) {
                    return <TwitterEmbed tweetUrl={twitterLink.props!.href!} />;
                }
            }

            // eslint-disable-next-line unused-imports/no-unused-vars
            const { children, node, ...rest } = props;

            if (!children) return null;

            return (
                <figure>
                    <blockquote {...rest}>{children}</blockquote>
                </figure>
            );
        },
        a: (props: any) => {
            const { href } = props;
            const link = href?.replace('cms.blockworks.co', 'blockworks.co');
            // Sometimes WordPress provides admin links, we just null these
            if (link && link.includes('wp-admin')) {
                return null;
            }

            if (!link) {
                return props.children;
            }

            return <Link {...props} href={link} />;
        },
        script: () => {
            return null;
        },
        chart: (props: ArticleChartProps) => <ArticleChart {...props} />,
        'dune-embed': DuneEmbed,
    },
    ...production,
} as Options;

const commonPreset = (components?: any) => {
    return {
        plugins: [
            rehypeSlug,
            [rehypeExternalLinks, { target: '_blank', rel: ['nofollow'] }],
            [rehypeReact, { ...defaultOptions, components: { ...defaultOptions.components, ...components } }],
        ],
    } as Preset;
};

export default commonPreset;
