import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';

import { cn } from '@blockworks/ui/utils';

export type PriceChangePlainProps = {
    priceChangePercent: number;
    className?: string;
    prefix?: string;
    suffix?: string;
    useColorCode?: boolean;
};

const PriceChangePlain = (props: PriceChangePlainProps) => {
    const prefix = props.prefix ? props.prefix : '';
    const suffix = props.suffix ? props.suffix : '%';
    const positive = props.priceChangePercent >= 0;
    let colorCode = '';
    if (props.useColorCode) {
        colorCode = positive ? 'text-green-700' : 'text-red-700';
    }

    return (
        <span className={cn('flex flex-nowrap items-center', colorCode, props.className)}>
            {positive ? (
                <ArrowUpIcon className={cn(' h-3 w-3 mr-0.5')} />
            ) : (
                <ArrowDownIcon className={cn(' h-3 w-3 mr-0.5')} />
            )}
            {prefix}
            {props.priceChangePercent && props.priceChangePercent.toFixed(2)}
            {suffix}
        </span>
    );
};

export { PriceChangePlain };
