import { createContext, useContext, useMemo, useReducer, useRef } from 'react';

const AudioPlayerContext = createContext(null);

const reducers = {
    SET_META: (state: any, action: any) => ({ ...state, meta: action.payload }),

    PLAY: (state: any) => ({ ...state, playing: true }),
    PAUSE: (state: any) => ({ ...state, playing: false }),
    TOGGLE_MUTE: (state: any) => ({ ...state, muted: !state.muted }),
    SET_CURRENT_TIME: (state: any, action: any) => ({ ...state, currentTime: action.payload }),
    SET_DURATION: (state: any, action: any) => ({ ...state, duration: action.payload }),
};

// @ts-ignore
const audioReducer = (state: any, action: any) => reducers[action.type](state, action);

export function AudioProvider({ children }: any) {
    const [state, dispatch] = useReducer(audioReducer, {
        playing: false,
        muted: false,
        duration: 0,
        currentTime: 0,
        meta: null,
    });
    const playerRef = useRef<HTMLAudioElement>(null);

    const actions = useMemo(() => {
        return {
            play: (data: any) => {
                if (data && data.audio) {
                    if (!playerRef) {
                        return;
                    }

                    dispatch({ type: 'SET_META', payload: data });

                    // @ts-ignore
                    if (playerRef!.current?.currentSrc !== data.audio.src) {
                        // @ts-ignore
                        const { playbackRate } = playerRef.current;
                        // @ts-ignore
                        playerRef!.current.src = data.audio.src;
                        // @ts-ignore
                        playerRef!.current.load();
                        // @ts-ignore
                        playerRef!.current.pause();
                        // @ts-ignore
                        playerRef!.current.playbackRate = playbackRate;
                        // @ts-ignore
                        playerRef!.current.currentTime = 0;
                    }
                }

                // @ts-ignore
                playerRef.current.play();
            },
            stop: () => {
                // @ts-ignore
                playerRef.current.pause();
                dispatch({ type: 'PAUSE', payload: null });
                dispatch({ type: 'SET_META', payload: null });
            },
            pause: () => {
                // @ts-ignore
                playerRef.current.pause();
            },
            toggle(data: any) {
                // eslint-disable-next-line no-unused-expressions
                this.isPlaying(data) ? actions.pause() : actions.play(data);
            },
            seekBy: (amount: any) => {
                // @ts-ignore
                playerRef.current.currentTime += amount;
            },
            seek: (time: any) => {
                // @ts-ignore
                playerRef.current.currentTime = time;
            },
            playbackRate: (rate: any) => {
                // @ts-ignore
                playerRef.current.playbackRate = rate;
            },
            // @ts-ignore
            getPlaybackRate: () => playerRef.current.playbackRate,
            toggleMute: () => {
                dispatch({ type: 'TOGGLE_MUTE' });
            },
            isPlaying: (data: any) => {
                if (data && data.audio) {
                    return state.playing && playerRef?.current?.currentSrc === data.audio.src;
                }

                return state.playing;
            },
        };
    }, [state.playing]);

    const api = useMemo(() => ({ ...state, ...actions }), [state, actions]);

    return (
        <>
            <AudioPlayerContext.Provider value={api}>{children}</AudioPlayerContext.Provider>
            <audio
                ref={playerRef}
                onPlay={() => dispatch({ type: 'PLAY' })}
                onPause={() => dispatch({ type: 'PAUSE' })}
                onTimeUpdate={event => {
                    dispatch({
                        type: 'SET_CURRENT_TIME',
                        // @ts-ignore
                        payload: Math.floor(event.target.currentTime),
                    });
                }}
                onDurationChange={event => {
                    dispatch({
                        type: 'SET_DURATION',
                        // @ts-ignore
                        payload: Math.floor(event.target.duration),
                    });
                }}
                muted={state.muted}
            />
        </>
    );
}

export const useAudioPlayer = (data: any) => {
    const player = useContext(AudioPlayerContext);

    return useMemo(
        () => ({
            // @ts-ignore
            ...player,
            play: () => {
                // @ts-ignore
                player.play(data);
            },
            toggle: () => {
                if (player) {
                    // @ts-ignore
                    player!.toggle(data);
                }
            },
            get playing() {
                // @ts-ignore
                return player?.isPlaying(data) || false;
            },
        }),
        [player, data],
    );
};
