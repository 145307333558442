/**
 * This is named to allow for syntax highlighting and formatting of CSS written insie of template string
 *
 * */
const styled = {
    minify: (template: TemplateStringsArray, ...vars: any) =>
        template
            .reduce((carry, next, i) => {
                return carry + vars[i - 1] + next;
            })
            .replace(/\r?\n|\r/g, '')
            .replace(/\s\s+/g, ' '),
};

export default styled;
