const tryJsonParse = <T = any>(value: any) => {
    let maybeJson: T = undefined as T;
    let isJsonString = true;
    try {
        maybeJson = JSON.parse(value);
    } catch (e) {
        console.log('safely failed to parse json', e);
        isJsonString = false;
    }

    return {
        isJsonString,
        parsedJson: maybeJson as T,
    };
};

export { tryJsonParse };
