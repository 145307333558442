import { ReactNode } from 'react';

import { cn } from '../../../utils';
import { ComponentOverrides } from '../../utils/hooks/use-abstract-processor';
import { useMarkdownProcessor } from '../../utils/hooks/use-markdown-processor';

interface RenderMarkdownProps<T extends ComponentOverrides> {
    text: string | null;
    overrides?: T;
    className?: string;
    fullWidth?: boolean;
    children?: ReactNode;
}

const RenderMarkdown = <T extends ComponentOverrides>({
    text,
    overrides,
    fullWidth,
    className,
    children,
}: RenderMarkdownProps<T>) => {
    const { content } = useMarkdownProcessor(text, overrides);

    return <div className={cn(fullWidth ? 'max-w-none' : '', className)}>{text ? content : children}</div>;
};

export { RenderMarkdown };
