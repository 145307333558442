import { fetcher } from '@blockworks/platform/api';

import { TickerItemType } from '@/layout/containers/default-container/ticker/ticker';

/**
 * Fetch stock data from yahoo using ./getSingleStockInfo.ts
 *
 * @param stockList
 */
const fetchTickerInfo = () => fetcher<false, void, TickerItemType[]>({ url: `/api/ticker` });

export default fetchTickerInfo;
